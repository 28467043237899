module.exports = function(ngModule) {

	ngModule.constant('FMApiUrl', 'https://semper-filemaker-api.herokuapp.com');

	var queueQueues = [
		{ value: 'general', label: 'General Queue' },
		{ value: 'local', label: 'Local Queue' }
	];

	var queueNames = [
		// Standard Worker tasks.
		{ value: 'agentMatching', label: 'agentMatching' },
		{ value: 'userCreate', label: 'userCreate' },
		{ value: 'userPatch', label: 'userPatch' },
		{ value: 'userSignedForm', label: 'userSignedForm' },
		{ value: 'userUpdateEmailStatus', label: 'userUpdateEmailStatus' },
		// Cron worker Tasks.
		{ value: 'emailJobOrder', label: 'emailJobOrder' },
		{ value: 'getJobSeekerStatusDev', label: 'getJobSeekerStatusDev' },

		{ value: 'emailRegistration', label: 'emailRegistration' },
		{ value: 'emailPasswordReset', label: 'emailPasswordReset' },
		{ value: 'emailPasswordUsername', label: 'emailPasswordUsername' },
		{ value: 'emailNewPasscode', label: 'emailNewPasscode' },
		{ value: 'userDetermineHomeOffice', label: 'userDetermineHomeOffice' },
		{ value: 'userSignedForm', label: 'userSignedForm' },
		{ value: 'adobeFormFetchData', label: 'adobeFormFetchData' },
		{ value: 'handleAdobeSignedForm', label: 'handleAdobeSignedForm' },
		{ value: 'userStoreFormS3', label: 'userStoreFormS3' },
		{ value: 'userStoreFormData', label: 'userStoreFormData' },
		{ value: 'applicationUpsert', label: 'applicationUpsert' },

		/* FM Import Tasks. */
		{ value: 'importFMJobs', label: 'importFMJobs' },
		{ value: 'importFMTalent', label: 'importFMTalent' },
		{ value: 'importFMSkills', label: 'importFMSkills' },
		{ value: 'importFMHomeOffices', label: 'importFMHomeOffices' },
		{ value: 'importFMSources', label: 'importFMSources' },

		{ value: 'userSyncSnapShot', label: 'userSyncSnapShot'},
		{ value: 'createClient', label: 'createClient'},
		{ value: 'userEducationUpsert', label: 'userEducationUpsert' },
		{ value: 'userReferenceUpsert', label: 'userReferenceUpsert' },
		{ value: 'userWorkHistoryUpsert', label: 'userWorkHistoryUpsert' },
		{ value: 'archiveCompletedQueueJobs', label: 'archiveCompletedQueueJobs' }
	];

	var queueStatuses = [
		{ value: 'queued', label: 'Queued' },
		{ value: 'dequeued', label: 'Dequeued' },
		{ value: 'complete', label: 'Complete' },
		{ value: 'failed', label: 'Failed' },
		{ value: 'cancelled', label: 'Cancelled' }
	];


	ngModule.constant('globallookups', {
		queue: {
			queue: queueQueues,
			name: queueNames,
			status: queueStatuses
		},
	});

	ngModule.constant('queueQueues', queueQueues);
	ngModule.constant('queueTasks', queueNames);
	ngModule.constant('queueStatuses', queueStatuses);

	ngModule.constant('skillYears', [
		{ value: 1, label: '' },
		{ value: 2, label: '' },
		{ value: 3, label: '' },
		{ value: 4, label: '' },
		{ value: 5, label: '' },
		{ value: 6, label: '' },
		{ value: 7, label: '' },
		{ value: 8, label: '' },
		{ value: 9, label: '' },
		{ value: 10, label: '' },
		{ value: 11, label: '' },
		{ value: 12, label: '' },
		{ value: 13, label: '' },
		{ value: 14, label: '' },
		{ value: 15, label: '' },
		{ value: 16, label: '' },
		{ value: 17, label: '' },
		{ value: 18, label: '' },
		{ value: 19, label: '' },
		{ value: 20, label: '' },
		{ value: 21, label: '' },
		{ value: 22, label: '' },
		{ value: 23, label: '' },
		{ value: 24, label: '' },
		{ value: 25, label: '' },
		{ value: 26, label: '' },
		{ value: 27, label: '' },
		{ value: 28, label: '' },
		{ value: 29, label: '' },
		{ value: 30, label: '' }
	]);

	ngModule.constant('country', [
	    {value: "Afghanistan", label: "Afghanistan"},
	    {value: "Albania", label: "Albania"},
	    {value: "Algeria", label: "Algeria"},
	    {value: "American Samoa", label: "American Samoa"},
	    {value: "Andorra", label: "Andorra"},
	    {value: "Angola", label: "Angola"},
	    {value: "Anguilla", label: "Anguilla"},
	    {value: "Antarctica", label: "Antarctica"},
	    {value: "Antigua And Barbuda", label: "Antigua And Barbuda"},
	    {value: "Argentina", label: "Argentina"},
	    {value: "Aruba", label: "Aruba"},
	    {value: "Australia", label: "Australia"},
	    {value: "Austria", label: "Austria"},
	    {value: "Azerbaijan", label: "Azerbaijan"},
	    {value: "Bahamas", label: "Bahamas"},
	    {value: "Bahrain", label: "Bahrain"},
	    {value: "Bangladesh", label: "Bangladesh"},
	    {value: "Barbados", label: "Barbados"},
	    {value: "Belarus", label: "Belarus"},
	    {value: "Belgium", label: "Belgium"},
	    {value: "Belize", label: "Belize"},
	    {value: "Benin", label: "Benin"},
	    {value: "Bermuda", label: "Bermuda"},
	    {value: "Bhutan", label: "Bhutan"},
	    {value: "Bolivia", label: "Bolivia"},
	    {value: "Bosnia And Herzegowina", label: "Bosnia And Herzegowina"},
	    {value: "Botswana", label: "Botswana"},
	    {value: "Bouvet Island", label: "Bouvet Island"},
	    {value: "Brazil", label: "Brazil"},
	    {value: "British Indian Ocean Territory", label: "British Indian Ocean Territory"},
	    {value: "Brunei Darussalam", label: "Brunei Darussalam"},
	    {value: "Bulgaria", label: "Bulgaria"},
	    {value: "Burkina Faso", label: "Burkina Faso"},
	    {value: "Burundi", label: "Burundi"},
	    {value: "Cambodia", label: "Cambodia"},
	    {value: "Cameroon", label: "Cameroon"},
	    {value: "Canada", label: "Canada"},
	    {value: "Cape Verde", label: "Cape Verde"},
	    {value: "Cayman Islands", label: "Cayman Islands"},
	    {value: "Central African Republic", label: "Central African Republic"},
	    {value: "Chad", label: "Chad"},
	    {value: "Chile", label: "Chile"},
	    {value: "China", label: "China"},
	    {value: "Christmas Island", label: "Christmas Island"},
	    {value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands"},
	    {value: "Colombia", label: "Colombia"},
	    {value: "Comoros", label: "Comoros"},
	    {value: "Congo", label: "Congo"},
	    {value: "Cook Islands", label: "Cook Islands"},
	    {value: "Costa Rica", label: "Costa Rica"},
	    {value: "Cote D'ivoire", label: "Cote D'ivoire"},
	    {value: "Croatia", label: "Croatia"},
	    {value: "Cuba", label: "Cuba"},
	    {value: "Cyprus", label: "Cyprus"},
	    {value: "Czech Republic", label: "Czech Republic"},
	    {value: "Denmark", label: "Denmark"},
	    {value: "Djibouti", label: "Djibouti"},
	    {value: "Dominica", label: "Dominica"},
	    {value: "Dominican Republic", label: "Dominican Republic"},
	    {value: "East Timor", label: "East Timor"},
	    {value: "Ecuador", label: "Ecuador"},
	    {value: "Egypt", label: "Egypt"},
	    {value: "El Salvador", label: "El Salvador"},
	    {value: "Equatorial Guinea", label: "Equatorial Guinea"},
	    {value: "Eritrea", label: "Eritrea"},
	    {value: "Estonia", label: "Estonia"},
	    {value: "Ethiopia", label: "Ethiopia"},
	    {value: "Falkland Islands (Malvinas)", label: "Falkland Islands (Malvinas)"},
	    {value: "Faroe Islands", label: "Faroe Islands"},
	    {value: "Fiji", label: "Fiji"},
	    {value: "Finland", label: "Finland"},
	    {value: "France", label: "France"},
	    {value: "French Guiana", label: "French Guiana"},
	    {value: "French Polynesia", label: "French Polynesia"},
	    {value: "French Southern Territories", label: "French Southern Territories"},
	    {value: "Gabon", label: "Gabon"},
	    {value: "Gambia", label: "Gambia"},
	    {value: "Georgia", label: "Georgia"},
	    {value: "Germany", label: "Germany"},
	    {value: "Ghana", label: "Ghana"},
	    {value: "Gibraltar", label: "Gibraltar"},
	    {value: "Greece", label: "Greece"},
	    {value: "Greenland", label: "Greenland"},
	    {value: "Grenada", label: "Grenada"},
	    {value: "Guadeloupes", label: "Guadeloupes"},
	    {value: "Guam", label: "Guam"},
	    {value: "Guatemala", label: "Guatemala"},
	    {value: "Guinea", label: "Guinea"},
	    {value: "Guinea-bissau", label: "Guinea-bissau"},
	    {value: "Guyana", label: "Guyana"},
	    {value: "Haiti", label: "Haiti"},
	    {value: "Heard And Mc Donald Islands", label: "Heard And Mc Donald Islands"},
	    {value: "Honduras", label: "Honduras"},
	    {value: "Hong Kong", label: "Hong Kong"},
	    {value: "Hungary", label: "Hungary"},
	    {value: "Iceland", label: "Iceland"},
	    {value: "India", label: "India"},
	    {value: "Indonesia", label: "Indonesia"},
	    {value: "Iran (Islamic Republic Of)", label: "Iran (Islamic Republic Of)"},
	    {value: "Iraq", label: "Iraq"},
	    {value: "Ireland", label: "Ireland"},
	    {value: "Israel", label: "Israel"},
	    {value: "Italy", label: "Italy"},
	    {value: "Jamaica", label: "Jamaica"},
	    {value: "Japan", label: "Japan"},
	    {value: "Jordan", label: "Jordan"},
	    {value: "Kazakhstan", label: "Kazakhstan"},
	    {value: "Kenya", label: "Kenya"},
	    {value: "Kiribati", label: "Kiribati"},
	    {value: "Korea (North)", label: "Korea (North)"},
	    {value: "Korea (South)", label: "Korea (South)"},
	    {value: "Kuwait", label: "Kuwait"},
	    {value: "Kyrgyzstan", label: "Kyrgyzstan"},
	    {value: "Latvia", label: "Latvia"},
	    {value: "Lebanon", label: "Lebanon"},
	    {value: "Lesotho", label: "Lesotho"},
	    {value: "Liberia", label: "Liberia"},
	    {value: "Libya", label: "Libya"},
	    {value: "Liechtenstein", label: "Liechtenstein"},
	    {value: "Lithuania", label: "Lithuania"},
	    {value: "Luxembourg", label: "Luxembourg"},
	    {value: "Macau", label: "Macau"},
	    {value: "Macedonia", label: "Macedonia"},
	    {value: "Madagascar", label: "Madagascar"},
	    {value: "Malawi", label: "Malawi"},
	    {value: "Malaysia", label: "Malaysia"},
	    {value: "Maldives", label: "Maldives"},
	    {value: "Mali", label: "Mali"},
	    {value: "Malta", label: "Malta"},
	    {value: "Marshall Islands", label: "Marshall Islands"},
	    {value: "Martinique", label: "Martinique"},
	    {value: "Mauritania", label: "Mauritania"},
	    {value: "Mauritius", label: "Mauritius"},
	    {value: "Mayotte", label: "Mayotte"},
	    {value: "Mexico", label: "Mexico"},
	    {value: "Micronesia", label: "Micronesia"},
	    {value: "Moldova", label: "Moldova"},
	    {value: "Monaco", label: "Monaco"},
	    {value: "Mongolia", label: "Mongolia"},
	    {value: "Montserrat", label: "Montserrat"},
	    {value: "Morocco", label: "Morocco"},
	    {value: "Mozambique", label: "Mozambique"},
	    {value: "Myanmar", label: "Myanmar"},
	    {value: "Namibia", label: "Namibia"},
	    {value: "Nauru", label: "Nauru"},
	    {value: "Nepal", label: "Nepal"},
	    {value: "Netherlands", label: "Netherlands"},
	    {value: "Netherlands Antille", label: "Netherlands Antille"},
	    {value: "New Caledonia", label: "New Caledonia"},
	    {value: "New Zealand", label: "New Zealand"},
	    {value: "Nicaragua", label: "Nicaragua"},
	    {value: "Niger", label: "Niger"},
	    {value: "Nigeria", label: "Nigeria"},
	    {value: "Niue", label: "Niue"},
	    {value: "Norfolk Island", label: "Norfolk Island"},
	    {value: "Northern Mariana Islands", label: "Northern Mariana Islands"},
	    {value: "Norway", label: "Norway"},
	    {value: "Oman", label: "Oman"},
	    {value: "Pakistan", label: "Pakistan"},
	    {value: "Palau", label: "Palau"},
	    {value: "Panama", label: "Panama"},
	    {value: "Papua New Guinea", label: "Papua New Guinea"},
	    {value: "Paraguay", label: "Paraguay"},
	    {value: "Peru", label: "Peru"},
	    {value: "Philippines", label: "Philippines"},
	    {value: "Pitcairn", label: "Pitcairn"},
	    {value: "Poland", label: "Poland"},
	    {value: "Portugal", label: "Portugal"},
	    {value: "Puerto Rico", label: "Puerto Rico"},
	    {value: "Qatar", label: "Qatar"},
	    {value: "Reunion", label: "Reunion"},
	    {value: "Romania", label: "Romania"},
	    {value: "Russia", label: "Russia"},
	    {value: "Russian Federation", label: "Russian Federation"},
	    {value: "Rwanda", label: "Rwanda"},
	    {value: "S. Georgia &amp; The S. Sandwich Islands", label: "S. Georgia &amp; The S. Sandwich Islands"},
	    {value: "Saint Kitts And Nevis", label: "Saint Kitts And Nevis"},
	    {value: "Saint Lucia", label: "Saint Lucia"},
	    {value: "Saint Vincent And The Grenadines", label: "Saint Vincent And The Grenadines"},
	    {value: "Samoa", label: "Samoa"},
	    {value: "San Marino", label: "San Marino"},
	    {value: "Sao Tome And Principe", label: "Sao Tome And Principe"},
	    {value: "Saudi Arabia", label: "Saudi Arabia"},
	    {value: "Senegal", label: "Senegal"},
	    {value: "Seychelles", label: "Seychelles"},
	    {value: "Sierra Leone", label: "Sierra Leone"},
	    {value: "Singapore", label: "Singapore"},
	    {value: "Slovakia (Slovak Republic)", label: "Slovakia (Slovak Republic)"},
	    {value: "Slovenia", label: "Slovenia"},
	    {value: "Solomon Islands", label: "Solomon Islands"},
	    {value: "Somalia", label: "Somalia"},
	    {value: "South Africa", label: "South Africa"},
	    {value: "Spain", label: "Spain"},
	    {value: "Sri Lanka", label: "Sri Lanka"},
	    {value: "St. Helena", label: "St. Helena"},
	    {value: "St. Pierre And Miquelon", label: "St. Pierre And Miquelon"},
	    {value: "Sudan", label: "Sudan"},
	    {value: "Suriname", label: "Suriname"},
	    {value: "Svalbard And Jan Mayen Islands", label: "Svalbard And Jan Mayen Islands"},
	    {value: "Swaziland", label: "Swaziland"},
	    {value: "Sweden", label: "Sweden"},
	    {value: "Switzerland", label: "Switzerland"},
	    {value: "Syrian Arab Republic", label: "Syrian Arab Republic"},
	    {value: "Taiwan", label: "Taiwan"},
	    {value: "Tajikistan", label: "Tajikistan"},
	    {value: "Tanzania", label: "Tanzania"},
	    {value: "Thailand", label: "Thailand"},
	    {value: "Togo", label: "Togo"},
	    {value: "Tokelau", label: "Tokelau"},
	    {value: "Tonga", label: "Tonga"},
	    {value: "Trinidad And Tobago", label: "Trinidad And Tobago"},
	    {value: "Tunisia", label: "Tunisia"},
	    {value: "Turkey", label: "Turkey"},
	    {value: "Turkmenistan", label: "Turkmenistan"},
	    {value: "Turks And Caicos Islands", label: "Turks And Caicos Islands"},
	    {value: "Tuvalu", label: "Tuvalu"},
	    {value: "Uganda", label: "Uganda"},
	    {value: "Ukraine", label: "Ukraine"},
	    {value: "United Arab Emirates", label: "United Arab Emirates"},
	    {value: "United Kingdom", label: "United Kingdom"},
	    {value: "US", label: "United States"},
	    {value: "United States Minor Outlying Islands", label: "United States Minor Outlying Islands"},
	    {value: "Uruguay", label: "Uruguay"},
	    {value: "Uzbekistan", label: "Uzbekistan"},
	    {value: "Vanuatu", label: "Vanuatu"},
	    {value: "Vatican City State (Holy See)", label: "Vatican City State (Holy See)"},
	    {value: "Venezuela", label: "Venezuela"},
	    {value: "Viet Nam", label: "Viet Nam"},
	    {value: "Virgin Islands (British)", label: "Virgin Islands (British)"},
	    {value: "Virgin Islands (U.S.)", label: "Virgin Islands (U.S.)"},
	    {value: "Wallis And Futuna Islands", label: "Wallis And Futuna Islands"},
	    {value: "Western Sahara", label: "Western Sahara"},
	    {value: "Worldwide", label: "Worldwide"},
	    {value: "Yemen", label: "Yemen"},
	    {value: "Yugoslavia", label: "Yugoslavia"},
	    {value: "Zaire", label: "Zaire"},
	    {value: "Zambia", label: "Zambia"},
	    {value: "Zimbabwe", label: "Zimbabwe"}
	]);

	ngModule.constant('degree', [
	    {value: "Associate's", label:" Associate's"},
	    {value: "Bachelor's", label:" Bachelor's"},
	    {value: "Master's", label:" Master's"},
	    {value: "PHD", label: "PhD"},
	    {value: "MD", label: "MD"},
	    {value: "DR", label: "Doctorate"},
	    {value: "MBA", label: "MBA"},
	    {value: "Certification", label: "Certification"},
	    {value: "Other", label: "Other"},
	    {value: "NA", label:" N/A"}
	]);

	ngModule.constant('schoolType', [
	    {value: "High", label: "High School"},
	    {value: "Trade", label: "Trade School"},
	    {value: "College", label: "College"},
	    {value: "Grad", label: "Grad School"},
	    {value: "Other", label: "Other"}
	]);

	ngModule.constant('year', [
	    {value: "select", label: "Select"},
	    {value: "1930", label: "1930"},
	    {value: "1931", label: "1931"},
	    {value: "1932", label: "1932"},
	    {value: "1933", label: "1933"},
	    {value: "1934", label: "1934"},
	    {value: "1935", label: "1935"},
	    {value: "1936", label: "1936"},
	    {value: "1937", label: "1937"},
	    {value: "1938", label: "1938"},
	    {value: "1939", label: "1939"},
	    {value: "1940", label: "1940"},
	    {value: "1941", label: "1941"},
	    {value: "1942", label: "1942"},
	    {value: "1943", label: "1943"},
	    {value: "1944", label: "1944"},
	    {value: "1945", label: "1945"},
	    {value: "1946", label: "1946"},
	    {value: "1947", label: "1947"},
	    {value: "1948", label: "1948"},
	    {value: "1949", label: "1949"},
	    {value: "1950", label: "1950"},
	    {value: "1951", label: "1951"},
	    {value: "1952", label: "1952"},
	    {value: "1953", label: "1953"},
	    {value: "1954", label: "1954"},
	    {value: "1955", label: "1955"},
	    {value: "1956", label: "1956"},
	    {value: "1957", label: "1957"},
	    {value: "1958", label: "1958"},
	    {value: "1959", label: "1959"},
	    {value: "1960", label: "1960"},
	    {value: "1961", label: "1961"},
	    {value: "1962", label: "1962"},
	    {value: "1963", label: "1963"},
	    {value: "1964", label: "1964"},
	    {value: "1965", label: "1965"},
	    {value: "1966", label: "1966"},
	    {value: "1967", label: "1967"},
	    {value: "1968", label: "1968"},
	    {value: "1969", label: "1969"},
	    {value: "1970", label: "1970"},
	    {value: "1971", label: "1971"},
	    {value: "1972", label: "1972"},
	    {value: "1973", label: "1973"},
	    {value: "1974", label: "1974"},
	    {value: "1975", label: "1975"},
	    {value: "1976", label: "1976"},
	    {value: "1977", label: "1977"},
	    {value: "1978", label: "1978"},
	    {value: "1979", label: "1979"},
	    {value: "1980", label: "1980"},
	    {value: "1981", label: "1981"},
	    {value: "1982", label: "1982"},
	    {value: "1983", label: "1983"},
	    {value: "1984", label: "1984"},
	    {value: "1985", label: "1985"},
	    {value: "1986", label: "1986"},
	    {value: "1987", label: "1987"},
	    {value: "1988", label: "1988"},
	    {value: "1989", label: "1989"},
	    {value: "1990", label: "1990"},
	    {value: "1991", label: "1991"},
	    {value: "1992", label: "1992"},
	    {value: "1993", label: "1993"},
	    {value: "1994", label: "1994"},
	    {value: "1995", label: "1995"},
	    {value: "1996", label: "1996"},
	    {value: "1997", label: "1997"},
	    {value: "1998", label: "1998"},
	    {value: "1999", label: "1999"},
	    {value: "2000", label: "2000"},
	    {value: "2001", label: "2001"},
	    {value: "2002", label: "2002"},
	    {value: "2003", label: "2003"},
	    {value: "2004", label: "2004"},
	    {value: "2005", label: "2005"},
	    {value: "2006", label: "2006"},
	    {value: "2007", label: "2007"},
	    {value: "2008", label: "2008"},
	    {value: "2009", label: "2009"},
	    {value: "2010", label: "2010"},
	    {value: "2011", label: "2011"},
	    {value: "2012", label: "2012"},
	    {value: "2013", label: "2013"},
	    {value: "2014", label: "2014"},
	    {value: "2015", label: "2015"},
	    {value: "N/A", label: "N/A"}
	]);

	ngModule.constant('salaryidXXX', [

	    // Need lookup table values for 4 salary labels. find TODO in the value
	    // and replace with values from ben
		{value: "", label: "<Any Salary>"},
	    {value: "990383", label: "$8.00 — $10.00/per hour"},
	    {value: "780015", label: "$10 - $15"},
	    {value: "780022", label: "$15 - $20"},
	    {value: "780012", label: "$20 - $25"},
	    {value: "990395", label: "$26 - $50"},
	    {value: "990392", label: "$25 - over"},
	    {value: "200Plus", label: "$200 - over"},
	    {value: "990389", label: "Draw + Commission"},
	    {value: "780003", label: "Negotiable"},
	    {value: "990357", label: "Salary + Bonus"},
	    {value: "990314", label: "Base Salary + Commission"},
	    {value: "990333", label: "20k - 25k"},
	    {value: "780021", label: "20K - 60K"},
	    {value: "990340", label: "25k - 30k"},
	    {value: "990385", label: "40K - 50K"},
	    {value: "780017", label: "50K - 60K"},
	    {value: "780020", label: "60K - 70K"},
	    {value: "780011", label: "70K - 80K"},
	    {value: "780018", label: "80K - 90K"},
	    {value: "780010", label: "90K - 100K"},
	    {value: "990394", label: "100K - 110K"},
	    {value: "780006", label: "110K - 120K"},
	    {value: "780013", label: "120K - 130K"},
	    {value: "120k150k", label: "120k - 150k"},
	    {value: "780008", label: "130K - over"},
	    {value: "150k-200k", label: "150k - 200k"},
	    {value: "200kPlus", label: "200k - over"}
	]);

	ngModule.constant('usState', [
	    {value: "NA", label: "N/A"},
	    {value: "AK", label: "Alaska"},
	    {value: "AL", label: "Alabama"},
	    {value: "AR", label: "Arkansas"},
	    {value: "AZ", label: "Arizona"},
	    {value: "CA", label: "California"},
	    {value: "CO", label: "Colorado"},
	    {value: "CT", label: "Connecticut"},
	    {value: "DC", label: "District of Columbia"},
	    {value: "DE", label: "Delaware"},
	    {value: "FL", label: "Florida"},
	    {value: "GA", label: "Georgia"},
	    {value: "HI", label: "Hawaii"},
	    {value: "IA", label: "Iowa"},
	    {value: "ID", label: "Idaho"},
	    {value: "IL", label: "Illinois"},
	    {value: "IN", label: "Indiana"},
	    {value: "KS", label: "Kansas"},
	    {value: "KY", label: "Kentucky"},
	    {value: "LA", label: "Louisiana"},
	    {value: "MA", label: "Massachusetts"},
	    {value: "MD", label: "Maryland"},
	    {value: "ME", label: "Maine"},
	    {value: "MI", label: "Michigan"},
	    {value: "MN", label: "Minnesota"},
	    {value: "MO", label: "Missouri"},
	    {value: "MS", label: "Mississippi"},
	    {value: "MT", label: "Montana"},
	    {value: "NC", label: "North Carolina"},
	    {value: "ND", label: "North Dakota"},
	    {value: "NE", label: "Nebraska"},
	    {value: "NH", label: "New Hampshire"},
	    {value: "NJ", label: "New Jersey"},
	    {value: "NM", label: "New Mexico"},
	    {value: "NV", label: "Nevada</"},
	    {value: "NY", label: "New York"},
	    {value: "OH", label: "Ohio"},
	    {value: "OK", label: "Oklahoma"},
	    {value: "OR", label: "Oregon"},
	    {value: "PA", label: "Pennsylvania"},
	    {value: "PR", label: "Puerto Rico"},
	    {value: "RI", label: "Rhode Island"},
	    {value: "SC", label: "South Carolina"},
	    {value: "SD", label: "South Dakota"},
	    {value: "TN", label: "Tennessee"},
	    {value: "TX", label: "Texas"},
	    {value: "UT", label: "Utah"},
	    {value: "VA", label: "Virginia"},
	    {value: "VT", label: "Vermont"},
	    {value: "WA", label: "Washington"},
	    {value: "WI", label: "Wisconsin"},
	    {value: "WV", label: "West Virginia"},
	    {value: "WY", label: "Wyoming"}
	]);

	// ngModule.constant('allSkills', [
	//     { primary: 'Hand Bindery/Fulfillment', secondary: 'Fulfillment' },
	//     { primary: 'Hand Bindery/Fulfillment', secondary: 'Hand Bindery' },
	//     { primary: 'Hand Bindery/Fulfillment', secondary: 'Mailroom' },
	//     { primary: 'Hand Bindery/Fulfillment', secondary: 'Pocket feeder' },
	//     { primary: 'Large Press', secondary: 'Lead Operator' },
	//     { primary: 'Large Press', secondary: '2nd Operator' },
	//     { primary: 'Large Press', secondary: 'Feeder' },
	//     { primary: 'Continuous Feed', secondary: 'B/W CF' },
	//     { primary: 'Continuous Feed', secondary: 'Color CF' },
	//     { primary: 'Web Press', secondary: 'Lead Operator' },
	//     { primary: 'Web Press', secondary: '2nd Operator' },
	//     { primary: 'Web Press', secondary: 'Roll Tender' },
	//     { primary: 'Web Press', secondary: 'Jogger' },
	//     { primary: 'Web Press', secondary: 'Heat Set' },
	//     { primary: 'Web Press', secondary: 'Cold Set' },
	//     { primary: 'Sales', secondary: 'Sr. Account Manager' },
	//     { primary: 'Sales', secondary: 'Inside' },
	//     { primary: 'Sales', secondary: 'Outside' },
	//     { primary: 'Sales', secondary: 'Telemarketer' },
	//     { primary: 'Planner', secondary: 'Corporate' },
	//     { primary: 'Planner', secondary: 'Trade' },
	//     { primary: 'Estimator/Buyer', secondary: 'Trade' },
	//     { primary: 'Estimator/Buyer', secondary: 'Buyer' },
	//     { primary: 'Supervisory/Management', secondary: 'Human Resources' },
	//     { primary: 'Supervisory/Management', secondary: 'Controller' },
	//     { primary: 'Supervisory/Management', secondary: 'Senior Manager' },
	//     { primary: 'Supervisory/Management', secondary: 'Office Manager' },
	//     { primary: 'Supervisory/Management', secondary: 'Plant Manager' },
	//     { primary: 'Supervisory/Management', secondary: 'Department Manager' },
	//     { primary: 'Flexographic', secondary: 'Wide Web' },
	//     { primary: 'Flexographic', secondary: 'Narrow Web' },
	//     { primary: 'Flexographic', secondary: 'Central Impression' },
	//     { primary: 'Small Press', secondary: 'Single Color' },
	//     { primary: 'Small Press', secondary: 'T-Head' },
	//     { primary: 'Small Press', secondary: '2 Tower' },
	//     { primary: 'Digital Press', secondary: 'Certified' },
	//     { primary: 'Digital Press', secondary: 'Non-Certified' },
	//     { primary: 'Premedia/Prepress', secondary: 'Color Correcting' },
	//     { primary: 'Premedia/Prepress', secondary: 'Copywriter' },
	//     { primary: 'Premedia/Prepress', secondary: 'Desktop Publishing' },
	//     { primary: 'Premedia/Prepress', secondary: 'Digital Preflight' },
	//     { primary: 'Premedia/Prepress', secondary: 'Offset Preflight' },
	//     { primary: 'Premedia/Prepress', secondary: 'Platemaking' },
	//     { primary: 'Premedia/Prepress', secondary: 'Proofing Operator' },
	//     { primary: 'Premedia/Prepress', secondary: 'Proofreading' },
	//     { primary: 'Premedia/Prepress', secondary: 'VDP (Variable Data Programmer)' },
	//     { primary: 'Online Media', secondary: 'Back End' },
	//     { primary: 'Online Media', secondary: 'Web Programmer' },
	//     { primary: 'Online Media', secondary: 'Social Media' },
	//     { primary: 'Online Media', secondary: 'Web Developer' },
	//     { primary: 'Online Media', secondary: 'Web Designer' },
	//     { primary: 'Online Media', secondary: 'SEO' },
	//     { primary: 'Online Media', secondary: 'OEM' },
	//     { primary: 'Online Media', secondary: 'Analytics' },
	//     { primary: 'Online Media', secondary: 'Content Creation' },
	//     { primary: 'IT', secondary: 'CAD' },
	//     { primary: 'IT', secondary: 'Database' },
	//     { primary: 'IT', secondary: 'Network' },
	//     { primary: 'IT', secondary: 'Programming' },
	//     { primary: 'Interactive Media', secondary: 'Engineer' },
	//     { primary: 'Interactive Media', secondary: 'Game Design' },
	//     { primary: 'Interactive Media', secondary: '3D Modeler/Artist' },
	//     { primary: 'Interactive Media', secondary: 'Animator' },
	//     { primary: 'Interactive Media', secondary: 'UI Design' },
	//     { primary: 'Interactive Media', secondary: 'Audio Engineer' },
	//     { primary: 'Interactive Media', secondary: 'Motion Graphics' },
	//     { primary: 'Interactive Media', secondary: 'System Architect' },
	//     { primary: 'Interactive Media', secondary: 'Augmented Reality' },
	//     { primary: 'CSR', secondary: 'Ad Agency' },
	//     { primary: 'CSR', secondary: 'Commercial Print' },
	//     { primary: 'CSR', secondary: 'Copy Shop' },
	//     { primary: 'CSR', secondary: 'Design Firm' },
	//     { primary: 'CSR', secondary: 'Financial Print' },
	//     { primary: 'Clerical', secondary: 'Accounting' },
	//     { primary: 'Clerical', secondary: 'Call Center' },
	//     { primary: 'Clerical', secondary: 'Support' },
	//     { primary: 'Clerical', secondary: 'Shipping/Receiving Coordinator' },
	//     { primary: 'Executive Management', secondary: 'CEO' },
	//     { primary: 'Executive Management', secondary: 'CFO' },
	//     { primary: 'Executive Management', secondary: 'COO' },
	//     { primary: 'Executive Management', secondary: 'Director' },
	//     { primary: 'Executive Management', secondary: 'CMO' },
	//     { primary: 'Executive Management', secondary: 'CIO' },
	//     { primary: 'Internal', secondary: 'Internal' },
	//     { primary: 'Bindery', secondary: 'Collator' },
	//     { primary: 'Bindery', secondary: 'Cutter' },
	//     { primary: 'Bindery', secondary: 'Diecutter' },
	//     { primary: 'Bindery', secondary: 'Folder' },
	//     { primary: 'Bindery', secondary: 'Gluer' },
	//     { primary: 'Bindery', secondary: 'Inserter Operator' },
	//     { primary: 'Bindery', secondary: 'Saddle Stitcher' },
	//     { primary: 'Bindery', secondary: 'Skilled Bindery' },
	//     { primary: 'Sewing', secondary: 'Banners' },
	//     { primary: 'Reprographics/Copy', secondary: 'Color Copier' },
	//     { primary: 'Reprographics/Copy', secondary: 'B/W Copier' },
	//     { primary: 'Reprographics/Copy', secondary: 'Grand Format' },
	//     { primary: 'Reprographics/Copy', secondary: 'Large Format' },
	//     { primary: 'Reprographics/Copy', secondary: 'Inkjet' },
	//     { primary: 'B/W High Speed', secondary: 'Certified' },
	//     { primary: 'B/W High Speed', secondary: 'Non-Certified' },
	//     { primary: 'Graphic Design', secondary: 'Creative' },
	//     { primary: 'Graphic Design', secondary: 'Designer' },
	//     { primary: 'Graphic Design', secondary: 'Illustrator' },
	//     { primary: 'Graphic Design', secondary: 'Production' },
	//     { primary: 'Silkscreen', secondary: 'Signage' },
	//     { primary: 'Silkscreen', secondary: 'Textile' },
	//     { primary: 'Silkscreen', secondary: 'Vinyl Graphics Installer' },
	//     { primary: 'Drivers', secondary: 'Drivers' },
	//     { primary: 'Warehouse', secondary: 'Forklift' },
	//     { primary: 'Warehouse', secondary: 'Shipping/Receiving' },
	//     { primary: 'Maintenance', secondary: 'Bindery' },
	//     { primary: 'Maintenance', secondary: 'Digital' },
	//     { primary: 'Maintenance', secondary: 'Electro/Mechanical' },
	//     { primary: 'Maintenance', secondary: 'Flexographic' },
	//     { primary: 'Maintenance', secondary: 'Offset Press' },
	//     { primary: 'Maintenance', secondary: 'Web Press' },
	//     { primary: 'Project Manager', secondary: 'Print Production Manager' },
	//     { primary: 'Project Manager', secondary: 'Producer' },
	//     { primary: 'Personal Trainer', secondary: 'Personal Trainer' },
	//     { primary: 'Wellness Instructor', secondary: 'Instructor' },
	//     { primary: 'Wellness admin', secondary: 'Front Desk' },
	//     { primary: 'Wellness Manager', secondary: 'Preventative Mgr' },
	//     { primary: 'Preventative Trainer', secondary: 'Personal Trainer' },
	//     { primary: 'Preventative Instructor', secondary: 'Spinning' },
	//     { primary: 'Preventative Admin', secondary: 'Front Desk ' },
	//     { primary: 'Preventative Manager', secondary: 'Preventative Mgr' },
	//     { primary: 'Personal Trainer', secondary: 'NSCA' },
	//     { primary: 'Wellness Instructor', secondary: 'Step' },
	//     { primary: 'Wellness Instructor', secondary: 'Yoga' },
	//     { primary: 'Wellness Instructor', secondary: 'Pilates' },
	//     { primary: 'Wellness Instructor', secondary: 'Martial Arts' },
	//     { primary: 'Wellness Instructor', secondary: 'Boxing' },
	//     { primary: 'Wellness Instructor', secondary: 'Zumba' },
	//     { primary: 'Wellness Instructor', secondary: 'Boot Camp' },
	//     { primary: 'Personal Trainer', secondary: 'ACSM' },
	//     { primary: 'Personal Trainer', secondary: 'ACE' },
	//     { primary: 'Personal Trainer', secondary: 'AFAA' },
	//     { primary: 'Wellness Instructor', secondary: 'Group Power' },
	//     { primary: 'Wellness Instructor', secondary: 'Muscle/Resistance' },
	//     { primary: 'Wellness Instructor', secondary: 'Kids Classes' },
	//     { primary: 'Wellness Instructor', secondary: 'Kettlebell' },
	//     { primary: 'Wellness Instructor', secondary: 'Belly Dancing' },
	//     { primary: 'Wellness Instructor', secondary: 'Ballroom Dancing' },
	//     { primary: 'Wellness Instructor', secondary: 'Senior Programs' },
	//     { primary: 'Wellness Instructor', secondary: 'Spinning' },
	//     { primary: 'Wellness Instructor', secondary: 'Water Aerobics' },
	//     { primary: 'Wellness Instructor', secondary: 'Barre' },
	//     { primary: 'Wellness Instructor', secondary: 'TRX' },
	//     { primary: 'Wellness Instructor', secondary: 'PlyoPower' },
	//     { primary: 'Wellness Instructor', secondary: 'Les Mills' },
	//     { primary: 'Wellness Instructor', secondary: 'Piloxing' },
	//     { primary: 'Wellness Instructor', secondary: 'Zumbatonic' },
	//     { primary: 'Wellness Instructor', secondary: 'YogaFit' },
	//     { primary: 'Health Fair', secondary: 'Health Fair Professional' },
	//     { primary: 'Preventative Manager', secondary: 'Dietitian' },
	//     { primary: 'Flu Shot', secondary: 'Flu Shot' },
	//     { primary: 'Wellness Instructor', secondary: 'Stress Managment' },
	//     { primary: 'Preventative Manager', secondary: 'Cooking Demonstration' },
	//     { primary: 'Preventative Manager', secondary: 'Lunch and Learns' },
	//     { primary: 'Preventative Manager', secondary: 'Stress Management' },
	//     { primary: 'Preventative Manager', secondary: 'Registered Dietitian' },
	//     { primary: 'Preventative Manager', secondary: 'Corporate Fitness Instructor' },
	//     { primary: 'Preventative Manager', secondary: 'Ergonomic Assessment' },
	//     { primary: 'Preventative Manager', secondary: 'Chair Massage' },
	//     { primary: 'Preventative Manager', secondary: 'Meditation/ Mindfulness' },
	//     { primary: 'Wellness admin', secondary: 'Sales Rep/Membership sales' },
	//     { primary: 'Cooking Demonstration', secondary: 'Cooking Demonstration' },
	//     { primary: 'Sewing', secondary: 'Embrodiery' },
	//     { primary: 'Graphic Design', secondary: 'Adobe Cloud' },
	// ]);

	ngModule.constant('expirationdate', [
	    {value: "oneWeek", label: '1 Week'},
	    {value: "twoWeeks", label: '2 Weeks'},
	    {value: "oneMonth", label: '1 Month'},
	    {value: "twoMonths", label: '2 Months'},
	    {value: "threeMonths", label: '3 Months'}
	]);

	ngModule.constant('skillid', [
		{value: "", label: "<Any Skill>"},
	    {value: "accounting", label: "Accounting"},
	    {value: "administrative", label: "Administrative"},
	    {value: "animator", label: "Animator"},
	    {value: "artist", label: "Artist"},
	    {value: "audioDesigner", label: "Audio Designer"},
	    {value: "bwHighSpeed", label: "B/W High Speed"},
	    {value: "bindery", label: "Bindery"},
	    {value: "buyer", label: "Buyer"},
	    {value: "clerical", label: "Clerical"},
	    {value: "continuousFeed", label: "Continuous Feed"},
	    {value: "csr", label: "CSR"},
	    {value: "designer", label: "Designer"},
	    {value: "digitalPress", label: "Digital Press"},
	    {value: "digitalPrint", label: "Digital Print"},
	    {value: "docutech", label: "Docutech"},
	    {value: "drivers", label: "Drivers"},
	    {value: "duplicators", label: "Duplicators"},
	    {value: "education", label: "Education"},
	    {value: "engineer", label: "Engineer"},
	    {value: "equipment", label: "Equipment"},
	    {value: "estimator", label: "Estimator"},
	    {value: "estimatorBuyer", label: "Estimator/Buyer"},
	    {value: "executiveLeadership", label: "Executive Leadership"},
	    {value: "executiveManagement", label: "Executive Management"},
	    {value: "finishing", label: "Finishing"},
	    {value: "flash", label: "Flash"},
	    {value: "flexo", label: "Flexo"},
	    {value: "flexographic", label: "Flexographic"},
	    {value: "gameDesign", label: "Game Design"},
	    {value: "gameTesters", label: "Game Testers"},
	    {value: "graphicDesign", label: "Graphic Design"},
	    {value: "handBinderyFulfillment", label: "Hand Bindery/Fulfillment"},
	    {value: "humanResources", label: "Human Resources"},
	    {value: "ink", label: "Ink"},
	    {value: "interactiveMedia", label: "Interactive Media"},
	    {value: "internal", label: "Internal"},
	    {value: "it", label: "IT"},
	    {value: "largePress", label: "Large Press"},
	    {value: "lettershop", label: "Lettershop"},
	    {value: "litigation", label: "Litigation"},
	    {value: "mailDelivery", label: "Mail / Delivery"},
	    {value: "maintenance", label: "Maintenance"},
	    {value: "modeler", label: "Modeler"},
	    {value: "music", label: "Music"},
	    {value: "newMedia", label: "New Media"},
	    {value: "newspaper", label: "Newspaper"},
	    {value: "onlineMedia", label: "Online Media"},
	    {value: "other", label: "Other"},
	    {value: "planner", label: "Planner"},
	    {value: "premediaPrepress", label: "Premedia/Prepress"},
	    {value: "prepress", label: "Prepress"},
	    {value: "prepressDigital", label: "Prepress-Digital"},
	    {value: "preventativeAdmin", label: "Preventative Admin"},
	    {value: "preventativeManager", label: "Preventative Manager"},
	    {value: "producer", label: "Producer"},
	    {value: "programmer", label: "Programmer"},
	    {value: "qualityControl", label: "Quality Control"},
	    {value: "reprographicsCopy", label: "Reprographics/Copy"},
	    {value: "retail", label: "Retail"},
	    {value: "sales", label: "Sales"},
	    {value: "secondLife", label: "Second Life"},
	    {value: "seriousGaming", label: "Serious Gaming"},
	    {value: "sewing", label: "Sewing"},
	    {value: "silkscreen", label: "Silkscreen"},
	    {value: "smallPress", label: "Small Press"},
	    {value: "supervisoryManagement", label: "Supervisory/Management"},
	    {value: "systemDesigner", label: "System Designer"},
	    {value: "telemarketing", label: "Telemarketing"},
	    {value: "trainerDemonstrator", label: "Trainer/Demonstrator"},
	    {value: "typeAndProofing", label: "Type and Proofing"},
	    {value: "uiDesigner", label: "UI Designer"},
	    {value: "variableData", label: "Variable Data"},
	    {value: "videoGameVirtualWorlds", label: "Video Game/Virtual Worlds"},
	    {value: "warehouse", label: "Warehouse"},
	    {value: "webDesigner", label: "Web Designer"},
	    {value: "webDeveloper", label: "Web Developer"},
	    {value: "webPress", label: "Web Press"},
	    {value: "webProgrammer", label: "Web Programmer"},
	    {value: "worldDesigner", label: "World Designer"},
	    {value: "wwwInternet", label: "WWW/Internet"}
	]);

	ngModule.constant('worktypeid', [
	    {value: "accountingFinanceManager", label: "Accounting / Finance Manager"},
	    {value: "accountingQuickbooks", label: "Accounting / Quickbooks"},
	    {value: "accountingCommercialPrint", label: "Accounting / Commercial Print"},
	    {value: "accountingArSpecialist", label: "Accounting / AR Specialist"},
	    {value: "accountingBillingCustomerServiceCoordinator", label: "Accounting / Billing &amp; Customer Service Coordinator"},
	    {value: "accountingPeachTreeMas", label: "Accounting / Peach Tree / Mas 90"}
	]);

	ngModule.constant('typejobid', [
	    { value: "full", label: "Full Time" },
	    { value: "part", label: "Part Time" },
		{ value: "either", label: "Either" },
	]);

	ngModule.constant('calc_work_type', [
		{ value: "", label: "<Any Work Type>"},
	    { value: "FT", label: "Full Time" },
	    { value: "PT", label: "Part Time" },
		{ value: "Either", label: "Either" },
	]);

	ngModule.constant('salaryid', [

	    // Need lookup table values for 4 salary labels. find TODO in the value
	    // and replace with values from ben
		{ value: "", label: "<Any Salary>"},
	    {value: "$8.00 — $10.00/per hour", label: "$8.00 — $10.00/per hour"},
	    {value: "$10 - $15", label: "$10 - $15"},
	    {value: "$15 - $20", label: "$15 - $20"},
	    {value: "$20 - $25", label: "$20 - $25"},
	    {value: "$26 - $50", label: "$26 - $50"},
	    {value: "$25 - over", label: "$25 - over"},
	    {value: "$200 - over", label: "$200 - over"},
	    {value: "Draw + Commission", label: "Draw + Commission"},
	    {value: "Negotiable", label: "Negotiable"},
	    {value: "Salary + Bonus", label: "Salary + Bonus"},
	    {value: "Base Salary + Commission", label: "Base Salary + Commission"},
	    {value: "20k - 25k", label: "20k - 25k"},
	    {value: "20K - 60K", label: "20K - 60K"},
	    {value: "25k - 30k", label: "25k - 30k"},
	    {value: "40K - 50K", label: "40K - 50K"},
	    {value: "50K - 60K", label: "50K - 60K"},
	    {value: "60K - 70K", label: "60K - 70K"},
	    {value: "70K - 80K", label: "70K - 80K"},
	    {value: "80K - 90K", label: "80K - 90K"},
	    {value: "90K - 100K", label: "90K - 100K"},
	    {value: "100K - 110K", label: "100K - 110K"},
	    {value: "110K - 120K", label: "110K - 120K"},
	    {value: "120K - 130K", label: "120K - 130K"},
	    {value: "120k - 150k", label: "120k - 150k"},
	    {value: "130K - over", label: "130K - over"},
	    {value: "150k - 200k", label: "150k - 200k"},
	    {value: "200k - over", label: "200k - over"}
	]);

	// ngModule.constant('shiftid', [
	//     {value: "first", label: "1st Shift"},
	//     {value: "second", label: "2nd Shift"},
	//     {value: "third", label: "3rd Shift"},
	//     {value: "any", label: "Any Shifts"},
	//     {value: "firstAndSecond", label: "1st and 2nd Shifts"},
	//     {value: "firstAndThird", label: "1st and 3rd Shifts"},
	//     {value: "secondAndThird", label: "2nd and 3rd Shifts"},
	//     {value: "cont", label: "Cont Shift"},
	//     {value: "firstAndCont", label: "1st and Cont Shifts"},
	//     {value: "secondAndCont", label: "2nd and Cont Shifts"},
	//     {value: "thirdAndCont", label: "3rd and Cont Shifts"},
	//     {value: "fistSecondAndCont", label: "1st, 2nd and Cont Shifts"},
	//     {value: "firstThirdAndCont", label: "1st, 3rd and Cont Shifts"},
	//     {value: "secondThirdAndCont", label: "2nd, 3rd and Cont Shifts"},
	//     {value: "firstSecondAndThird", label: "1st, 2nd and 3rd Shifts"}
	// ]);

	ngModule.constant('shiftid', [
		{value: "", label: "<Any Shift>"},
		{value: "1", label: "1st Shift"},
		{value: "2", label: "2nd Shift"},
		{value: "1,2", label: "1st & 2nd Shifts"},
	]);

	ngModule.constant('homeOffice', [
	    { value: "atlanta", label: "Atlanta", id: '107' },
	    { value: "baltimore", label: "Baltimore", id: '109' },
	    { value: "boston", label: "Boston", id: '100' },
	    { value: "chicago", label: "Chicago", id: '103' },
	    { value: "dallas", label: "Dallas", id: '106' },
	    { value: "losAngeles", label: "Los Angeles", id: '108' },
	    { value: "minneapolisStPaul", label: "Minneapolis-St Paul", id: '120' },
	    { value: "newJersey", label: "New Jersey", id: '118' },
	    { value: "newYorkCity", label: "New York", id: '122' },
	    { value: "orangeCounty", label: "Orange County", id: '117'},
	    { value: "sanDiego", label: "San Diego", id: '121' },
	    { value: "sanFrancisco", label: "San Francisco", id: '114' },
	    { value: "santaClara", label: "Santa Clara", id: '115' }
	]);

	ngModule.constant('yesno', [
		{ value: 'yes', label: 'Yes' },
		{ value: 'no', label: 'No' },
	]);

	ngModule.constant('booleanYesNo', [
		{ value: true, label: 'Yes' },
		{ value: false, label: 'No' },
	]);

	ngModule.constant('source', [
		{ value: 'Personal Referral', label: 'Personal Referral' },
		{ value: 'Website', label: 'Website' },
		{ value: 'Newspaper', label: 'Newspaper' },
		{ value: 'Trade Show/Job Fair', label: 'Trade Show/Job Fair' },
		{ value: 'Trade Magazine', label: 'Trade Magazine' },
		{ value: 'Mailer', label: 'Mailer' },
		{ value: 'Web Search', label: 'Web Search' },
		{ value: 'Adwords', label: 'Adwords' },
		{ value: 'Facebook', label: 'Facebook' },
		{ value: 'Facebook', label: 'Facebook' },
		{ value: 'Twitter', label: 'Twitter' },
		{ value: 'Google Plus', label: 'Google Plus' },
		{ value: 'Glassdoor', label: 'Glassdoor' },
		{ value: 'Email', label: 'Email' },
		{ value: 'Indeed', label: 'Indeed' },
		{ value: 'SimplyHired', label: 'SimplyHired' },
		{ value: 'ZipRecruiter', label: 'ZipRecruiter' },
		{ value: 'Semper/Tech Creative Blog', label: 'Semper/Tech Creative Blog' },
		{ value: 'Word of Mouth/Referral', label: 'Word of Mouth/Referral' },
		{ value: 'Industry Ad', label: 'Industry Ad' },
		{ value: 'Direct Mail/Postcard', label: 'Direct Mail/Postcard' },
		{ value: 'Trade Show/speaking', label: 'Trade Show/speaking' },
		{ value: 'Trade association', label: 'Trade association' },
		{ value: 'Used in Past', label: 'Used in Past' },
		{ value: 'Sales Call', label: 'Sales Call' },
		{ value: 'Other', label: 'Other' }
	]);
};
